import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import * as reducers from './ducks'; // import all reducers from ducks/index.js

const createRootReducer = (history) => ({
  ...reducers,
  router: connectRouter(history),
  form: formReducer,
});

const store = (initialState = {}, history) =>
  configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), routerMiddleware(history)],
    preloadedState: initialState,
  });

export default store;
