// ターゲットセクション以降にスクロールトップアイコンを表示
window.addEventListener('load', () => {
  // ページトップアイコン
  const widgetObject = document.getElementById('js-scrollTop--btn');
  // ターゲットセクション（ここまできたら表示）
  const targetSection = document.getElementById('js-scrollTop--target');

  if (!widgetObject || !targetSection) return;

  const SCROLL = targetSection.getBoundingClientRect().top + window.scrollY;

  window.addEventListener('scroll', () => {
    widgetObject.dataset.visible = (window.scrollY > SCROLL).toString();
  });

  widgetObject.addEventListener('click', () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  });
});
