// import Swiper JS
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';

document.addEventListener('DOMContentLoaded', () => {
  voiceSlider.init();
  courseSlider.init();
});

window.matchMedia('(min-width: 769px)').addEventListener('change', () => {
  courseSlider.update();
  voiceSlider.update();
});

// 受講生の声
const voiceSlider = new Swiper('.p-top-voice__slider', {
  modules: [Pagination, Navigation],
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  loop: true,
  cssMode: true,
  spaceBetween: 20,
  centeredSlides: true,
  speed: 2000,
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 68,
    },
  },
  pagination: {
    el: '.c-carousel__dots',
    bulletActiveClass: 'c-carousel__dot--active',
    bulletClass: 'c-carousel__dot',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: '.c-carousel__btn--next',
    prevEl: '.c-carousel__btn--prev',
  },
});

// おすすめコース
const courseSlider = new Swiper('.p-top-popular__slider', {
  modules: [Pagination, Navigation],
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  loop: true,
  cssMode: true,
  spaceBetween: 20,
  centeredSlides: true,
  speed: 2000,
  breakpoints: {
    768: {
      centeredSlides: false,
      slidesPerView: 4,
      // NOTE: スライダー無効
      loop: false,
      watchOverflow: true,
    },
  },
  pagination: {
    el: '.c-carousel__dots',
    bulletActiveClass: 'c-carousel__dot--active',
    bulletClass: 'c-carousel__dot',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: '.c-carousel__btn--next',
    prevEl: '.c-carousel__btn--prev',
  },
});
